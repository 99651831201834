<template>
  <div>
    <div class="col-md-10 offset-md-1">
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              Zone Details | {{ form.origin }}
              <i class="flaticon2-right-arrow text-dark icon-nm"></i>
              {{ form.destination }}
            </h3>
          </div>
          <div class="card-toolbar">
            <a
              class="btn btn-secondary mr-2"
              :href="`${publicPath}files/Example.xlsx`"
              download
              >Download</a
            >
            <b-button variant="success" @click="trigger"
              ><i class="flaticon-download"></i> Import</b-button
            >
            <b-form-file
              accept=".xlsx, .xls"
              plain
              hidden
              ref="fileInput"
              style="display: none"
              class="form-control"
              @change="onFileChange($event)"
            ></b-form-file>
          </div>
        </div>
        <!-- <div class="card-body body-fluid">
          <div class="row">
            <div class="col-md-4">
              <h1 class="text-center">{{ form.origin }}</h1>
            </div>
            <div class="col-md-3 offset-1">
              <i class="flaticon2-right-arrow text-center icon-lg"></i>
            </div>
            <div class="col-md-4">
              <h1 class="text-center">{{ form.destination }}</h1>
            </div>
          </div>
        </div> -->
      </div>
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Sub Rates</h3>
          </div>
        </div>
        <div class="card-body body-fluid">
          <div class="row">
            <div class="col-md-12">
              <b-table
                responsive
                class="text-nowrap"
                striped
                hover
                show-empty
                :items="form.subrates"
                :fields="fields"
              >
                <template #cell(actions)="row">
                  <b-button
                    size="sm"
                    @click="ShowEditSubrateModal(row.item.id)"
                    variant="primary"
                  >
                    Edit</b-button
                  >
                  <b-button
                    @click="deleteSubRate(row.item.id)"
                    class="ml-2"
                    size="sm"
                    variant="danger"
                  >
                    Delete</b-button
                  >
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Add Sub Rate</h3>
          </div>
        </div>
        <div class="card-body body-fluid">
          <div class="row">
            <div class="col-md-4">
              <b-form-group label="Rate Type">
                <b-form-select
                  :options="rate_type_list"
                  value-field="val"
                  v-model="subrateform.rate_type"
                  text-field="name"
                  class="form-control"
                >
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Weight">
                <b-form-input
                  min="0.00"
                  placeholder="0.5"
                  step="0.1"
                  v-model="subrateform.weight"
                ></b-form-input>
              </b-form-group>
            </div>
            <!-- <div class="col-md-2">
              <b-form-group label="To">
                <b-form-input
                  type="number"
                  min="0.00"
                  placeholder="1.0"
                  step="0.1"
                  v-model="subrateform.to"
                ></b-form-input>
              </b-form-group>
            </div>
       
            <div class="col-md-2">
              <b-form-group label="Charge Type">
                <b-form-select
                  :options="charge_type_list"
                  value-field="val"
                  v-model="subrateform.charge_type"
                  text-field="name"
                  class="form-control"
                >
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-2">
              <b-form-group label="Apply Type">
                <b-form-select
                  :options="apply_type_list"
                  value-field="val"
                  v-model="subrateform.apply_type"
                  text-field="name"
                  class="form-control"
                >
                </b-form-select>
              </b-form-group>
            </div> -->
            <div class="col-md-4">
              <b-form-group label="Rate">
                <b-form-input
                  type="number"
                  placeholder="0.00"
                  min="0.00"
                  step="0.01"
                  v-model="subrateform.rate"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-button variant="primary" @click="addSubrate"
                >Save Subrate</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="edit-subrate-modal"
      ref="modal"
      @hidden="resetSubrateForm"
      @ok="updateSubrate"
      title="Edit Subrate"
    >
      <form ref="editform" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <b-form-group label="Rate Type">
                  <b-form-select
                    :options="rate_type_list"
                    value-field="val"
                    v-model="subrateform.rate_type"
                    text-field="name"
                    class="form-control"
                  >
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="Weight">
                  <b-form-input
                    min="0.00"
                    placeholder="0.5"
                    step="0.1"
                    v-model="subrateform.weight"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="Rate">
                  <b-form-input
                    type="number"
                    placeholder="0.00"
                    min="0.00"
                    step="0.01"
                    v-model="subrateform.rate"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <loading :active.sync="isLoading"></loading>
  </div>
</template>
<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import XLSX from "xlsx";
export default {
  data() {
    return {
      id: this.$route.params.id,
      publicPath: process.env.BASE_URL,
      form: {},
      // file:any,
      isLoading: false,
      fields: [
        { key: "weight", label: "Weight" },
        // { key: "to", label: "To" },
        { key: "rate_type", label: "Rate Type" },
        // { key: "charge_type", label: "Charge Type" },
        // { key: "apply_type", label: "Apply Type" },
        {
          key: "rate",
          label: "Rate",
          formatter: (value) => {
            return value + " " + "SGD";
          },
        },
        { key: "actions", label: "Actions" },
      ],
      subrateform: {
        id: null,
        shipping_rate_id: this.$route.params.id,
        weight: null,
        // to: null,
        rate_type: "per/range",
        // charge_type: "amount",
        // apply_type: "add",
        rate: null,
      },
      rate_type_list: [
        {
          val: "per/range",
          name: "Per/range",
        },
        {
          val: "per/each/weight",
          name: "Per/Each",
        },
      ],
      // charge_type_list: [
      //   {
      //     val: "amount",
      //     name: "Amount",
      //   },
      //   {
      //     val: "percentage",
      //     name: "Percentage",
      //   },
      // ],
      // apply_type_list: [
      //   {
      //     val: "add",
      //     name: "Add",
      //   },
      //   {
      //     val: "per/each/weight",
      //     name: "Per/Each",
      //   },
      // ],
      //   currencies: [],
      //   errors: [],
      //   fields: ["code", "conversion_rate", "actions"],
      //   form: {
      //     code: null,
      //     conversion_rate: null,
      //     id: null,
      //   },
    };
  },
  created() {
    this.getRate();
  },
  components: {
    Loading,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Shipping" },
      { title: "Rate" },
      { title: "Edit Rate" },
    ]);
  },
  methods: {
    trigger() {
      this.$refs.fileInput.$el.click();
    },
    onFileChange(event) {
      var file = event.target.files ? event.target.files[0] : null;
      // var data=[];
      // var rows = [];
      if (file) {
        this.isLoading = true;

        const reader = new FileReader();

        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          data.shift();
          var importData = [];
          var self = this;
          data.map(function (value) {
            var arr = {};
            arr.shipping_rate_id = self.id;
            if (value[0].trim().toLowerCase() == "range") {
              arr.rate_type = "per/range";
            }
            if (value[0].trim().toLowerCase() == "each") {
              arr.rate_type = "per/each/weight";
            }
            // arr.rate_type = value[0];
            arr.weight = value[1];
            arr.rate = value[2];
            importData.push(arr);
          });
          // var obj = {};
          // obj.importData = importData;

          // data.forEach(
          //   (value) => (value["shipping_rate_id"] = this.$route.params.id)
          // );

          // $.each(data,function(key,value){
          //     value['shipping_rate_id']=this.$route.params.id;
          // });
          // console.log(importData);

          ApiService.post("shippingsubrateimport", importData)
            .then(() => {
              this.getRate();
              // this.resetSubrateForm();
              this.makeToastVariant(
                "success",
                "Subrates added successfully",
                "Success"
              );
            })
            .catch(({ response }) => {
              this.isLoading = false;
              for (let field of Object.keys(response.data.error)) {
                this.makeToastVariant(
                  "danger",
                  response.data.error[field][0],
                  "Error"
                );
              }
            });

          // rows = data;
          // console.log(rows);
        };

        reader.readAsBinaryString(file);
        // console.log(rows);
        // console.log( reader.readAsBinaryString(this.file));
        // console.log(this.file);
        // data.shift();
        // console.log(data);
      }
    },
    makeToastVariant(variant = null, text, title) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    resetSubrateForm() {
      this.subrateform.weight = null;
      // this.subrateform.to = null;
      this.subrateform.rate_type = "per/range";
      // this.subrateform.charge_type = "amount";
      // this.subrateform.apply_type = "add";
      this.subrateform.rate = null;
    },
    CheckErrorsSubRateForm() {
      this.Loading = true;
      if (this.subrateform.weight && this.subrateform.rate) {
        this.Loading = false;
        return true;
      }

      this.errors = [];

      if (!this.subrateform.weight) {
        this.errors.push("Weight is required.");
      }
      if (!this.subrateform.rate) {
        this.errors.push("Rate is required.");
      }
      for (let field of this.errors) {
        this.makeToastVariant("danger", field, "Error");
      }
      this.Loading = false;
    },
    addSubrate() {
      if (this.CheckErrorsSubRateForm()) {
        this.Loading = true;
        ApiService.post("shippingsubrate", this.subrateform)
          .then(() => {
            this.getRate();
            this.resetSubrateForm();
            this.makeToastVariant(
              "success",
              "Subrate added successfully",
              "Success"
            );
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    updateSubrate() {
      if (this.CheckErrorsSubRateForm()) {
        this.Loading = true;
        ApiService.update(
          "shippingsubrate",
          this.subrateform.id,
          this.subrateform
        )
          .then(() => {
            this.getRate();
            this.resetSubrateForm();
            this.makeToastVariant(
              "info",
              "Subrate updated successfully",
              "Success"
            );
          })
          .catch(({ response }) => {
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    getRate() {
      this.isLoading = true;
      ApiService.get("shippingrate", this.id)
        .then(({ data }) => {
          this.form = data.shippingrate;
          this.isLoading = false;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    ShowEditSubrateModal(id) {
      this.isLoading = true;
      ApiService.get("shippingsubrate", id)
        .then(({ data }) => {
          
          this.subrateform = data.shippingsubrate;
          this.$bvModal.show("edit-subrate-modal");
          this.isLoading = false;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    deleteSubRate(id) {
      this.isLoading = true;
      ApiService.delete("shippingsubrate", id)
        .then(() => {
          this.getRate();
          this.makeToastVariant(
            "warning",
            "Subrate deleted successfully",
            "Success"
          );
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
  },
};
</script>
